import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const AbandonedCarts = styled.div`
  border-radius: 4px;
  position: relative;
  border-top: 1px solid #e4e5e8;
  display: grid;
  grid-template-areas: 'content images';
  grid-template-columns: 426px 1fr;
  grid-template-rows: auto;
  padding-top: 168px;
  padding-bottom: 40px;

  & .BrainhubCarousel__container {
    grid-area: images;
    transform: translateX(64px);
  }
  & .BrainhubCarouselItem {
    justify-content: flex-start;
    align-items: flex-start;
  }
  & .gatsby-image-wrapper {
    width: 100%;
  }
  & p {
    margin-bottom: 0;
  }
  & h2.line {
    margin-bottom: 16px;
    & span::after {
      background-color: ${({ color }) => color || '#ffca00'};
    }
  }
  & .content-container {
    grid-area: content;
  }
  &.secondary {
    grid-column-gap: 111px;
    & .BrainhubCarousel__container {
      transform: translateX(0);
    }
    & .gatsby-image-wrapper {
      margin-right: 1px;
    }
  }
  &.reversed {
    grid-template-areas: 'images content';
    grid-template-columns: 1fr 426px;
  }

  ${media.desktopWide} {
    &.secondary {
      grid-column-gap: 55px;
    }
  }
  ${media.desktop} {
    padding-bottom: 90px;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-template-areas:
      'content'
      'images';
    grid-template-rows: repeat(2, min-content);
    padding-top: 90px;
    grid-row-gap: 40px;
    text-align: center;
    & .BrainhubCarousel__container {
      transform: translateX(0);
    }
    &.reversed {
      grid-template-areas:
        'content'
        'images';
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, min-content);
      & .BrainhubCarousel__container {
        transform: translateX(0);
      }
    }
  }
  ${media.tablet} {
    border-top: none;
  }
  ${media.mobile} {
    padding-bottom: 30px;
    .gallery-img {
      display: none;
    }
    &::before {
      display: none;
    }
  }
`;

export const Card = styled.button`
  border: none;
  padding: 24px;
  transition: height 0.2s ease;
  background-color: transparent;
  border-radius: 4px;
  &:hover:not(.active) {
    text-decoration: underline;
  }
  & div {
    display: flex;
    align-items: center;
    & h4 {
      font-weight: 800;
      font-size: 18px;
      line-height: 28px;
      margin-right: 16px;
      color: #000;
    }
  }
  & p {
    font-size: 16px;
    line-height: 26px;
    margin-top: 8px;
    margin-bottom: 0;
    display: none;
    text-align: left;
  }
  &:hover:not(&:disabled) {
    cursor: pointer;
  }

  & div.in-progress {
    position: relative;
    &::after {
      content: 'In progress';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(135%, -50%);
      padding: 1px 6px;
      background-color: #ffca00;
      font-size: 14px;
      line-height: 18px;
      color: #000;
      font-weight: 700;
      border-radius: 4px;
    }
  }
  & svg {
    transition: transform 0.2s ease;
  }
  &.active {
    background-color: ${({ color }) => color || '#ffca00'};
    color: ${({ color }) => (color ? (color === '#8864FE' ? '#fff' : '#000') : '#000')};
    & p {
      display: block;
    }
    & h4 {
      color: ${({ color }) => (color ? (color === '#8864FE' ? '#fff' : '#000') : '#000')};
    }
    & svg path {
      fill: ${({ color }) => (color ? (color === '#8864FE' ? '#fff' : '#000') : '#000')};
    }
    & svg {
      transform: rotate(180deg);
    }
  }
`;

export const CardContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  ${Card}:nth-of-type(2) {
    &.active {
      color: ${({ color }) => (color ? (color === '#8864FE' ? '#fff' : '#000') : '#000')};
      background-color: ${({ color }) => color || '#7DFFEE'};
      & h4 {
        color: ${({ color }) => (color ? (color === '#8864FE' ? '#fff' : '#000') : '#000')};
      }
      & svg path {
        fill: ${({ color }) => (color ? (color === '#8864FE' ? '#fff' : '#000') : '#000')};
      }
    }
  }
  ${Card}:nth-of-type(3) {
    &.active {
      color: ${({ color }) => (color ? (color === '#8864FE' ? '#fff' : '#000') : '#fff')};
      background-color: ${({ color }) => color || '#8864FE'};
      & h4 {
        color: ${({ color }) => (color ? (color === '#8864FE' ? '#fff' : '#000') : '#fff')};
      }
      & svg path {
        fill: ${({ color }) => (color ? (color === '#8864FE' ? '#fff' : '#000') : '#fff')};
      }
    }
  }
  ${Card}:nth-of-type(4) {
    &.active {
      color: ${({ color }) => (color ? (color === '#8864FE' ? '#fff' : '#000') : '#fff')};
      background-color: ${({ color }) => color || '#000'};
      & h4 {
        color: ${({ color }) => (color ? (color === '#8864FE' ? '#fff' : '#000') : '#fff')};
      }
      & svg path {
        fill: ${({ color }) => (color ? (color === '#8864FE' ? '#fff' : '#000') : '#fff')};
      }
    }
  }
  ${Card}:last-of-type {
    cursor: initial;
    &:hover {
      text-decoration: none;
    }
  }
`;
