import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Carousel from '@brainhubeu/react-carousel';
import Img from 'gatsby-image/withIEPolyfill';
import ChevronIcon from 'img/ui/chevron-down.inline.svg';
import * as Styled from './styled';
import '@brainhubeu/react-carousel/lib/style.css';

const FeaturesSlider = ({
  sectionTitle,
  sectionDesc,
  images,
  cards,
  reversed,
  color,
  style,
  className,
}) => {
  const [activeCard, setActiveCard] = useState(0);

  const onChange = (value) => {
    setActiveCard(value);
  };

  return (
    <Styled.AbandonedCarts
      className={[reversed ? 'reversed' : '', 'features-slider', className].join(' ')}
      color={color}
      style={style}
    >
      <div className="content-container">
        {sectionTitle || (
          <h2 className="line">
            Recover <span>abandoned</span> carts
          </h2>
        )}
        <p>
          {sectionDesc ||
            `There are many tactics for recovering abandoned carts, but good old-fashioned UX research
          will remain irreplaceable.`}
        </p>
        <Styled.CardContainer color={color}>
          {cards.map(({ desc, title, id }) => (
            <Styled.Card
              key={title}
              onClick={() => setActiveCard(id)}
              className={activeCard === id ? 'active' : ''}
              color={color}
            >
              <div>
                <h4>{title}</h4>
                <ChevronIcon />
              </div>
              <p>{desc}</p>
            </Styled.Card>
          ))}
        </Styled.CardContainer>
      </div>
      {typeof window !== `undefined` && (
        <Carousel value={activeCard} onChange={onChange}>
          {images.map((item) => (
            <Img
              fluid={item?.childImageSharp?.fluid || item?.node?.childImageSharp?.fluid}
              alt="session"
              objectFit="contain"
              key={item}
            />
          ))}
        </Carousel>
      )}
    </Styled.AbandonedCarts>
  );
};

export default FeaturesSlider;

FeaturesSlider.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
  sectionDesc: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      desc: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  reversed: PropTypes.bool,
  color: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  className: PropTypes.string,
};

FeaturesSlider.defaultProps = {
  reversed: false,
  color: undefined,
  style: undefined,
  className: '',
};
